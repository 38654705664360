import { atom, selector } from "recoil";

interface CombinedResponse {
   response: string;
   considerationDetails?: ConsiderationDetails[];
   legalArea?: string;
   templateConsiderationDetails?: ConsiderationDetails[];
   confidence: number;
}

interface Document {
   Type: string;
   apireply: ConsiderationDetails;
}

interface FormattedReply {
   Text: string;
   Citations: string[];
}

interface FormattedFastReply {
   Text: string;
   Citations: string[];
}

type ConsiderationDetails = {
   label: string;
   title: string;
   url: string;
   relevant_ids: number[];
   article_citations: string[];
   article_paragraphs_text: string[];
};

interface Coverage {
   contractName: string;
   caseCoverage: string;
   conditionsCoverage: string;
   moduleCoverage: string;
}

interface Question {
   id: number;
   fastDocuments: Document[];
   documents: Document[];
   coverage: Coverage[];
   formattedFastReplies: FormattedFastReply[];
   formattedReplies: FormattedReply[];
   question: string;
   legalArea?: string;
   confidence?: string;
   ownerUsername?: string;
   ownerGroup?: string;
   date: Date;
}

export const available_AVB: string[] = [
   "Private 2020",
   "Small Business 2020",
   "Business 2020",
   "Private L 2021",
   "Private XL 2021",
   "Private Flex 2021",
   "Business Flex 2021",
   "Business L 2021",
   "Business XL 2021",
   "Private L 2022",
   "Private XL 2022",
   "Private Flex 2022",
   "Business L 2022",
   "Business XL 2022",
   "Business Flex 2022",
   "GM Legis priva 2022",
   "GM Legis strada 2022",
   "GM Legis duo 2022",
   "GM Legis sana 2021",
   "GM Legis sana plus 2024",
];

export const historyIndex = atom<number>({
   key: "historyIndex",
   default: 0,
});

export const history = atom<Question[]>({
   key: "history",
   default: [
      {
         id: 0,
         fastDocuments: [],
         documents: [],
         coverage: [],
         formattedReplies: [],
         formattedFastReplies: [],
         question: "",
         legalArea: undefined,
         date: new Date(),
      },
   ],
});

export const historyLength = selector({
   key: "historyLength",
   get: ({ get }) => {
      const historyArray = get(history);
      return historyArray.length;
   },
});

export const debugModeGlobal = atom<boolean>({
   key: "debugModeGlobal",
   default: false,
});

export const answerSettingGlobal = atom<string>({
   key: "answerSettingGlobal",
   default: "Fast-Text",
});

type langType = 'en' | 'it' | 'fr' | 'de';
export const available_Languages : {
   code: langType;
   name: string;
}[] = [
   {code : "de", name : "Deutsch"} ,{code : "fr", name : "Français"} , {code : "it", name : "Italiano"} 
]
export const languageSettingGlobal = atom<langType>({
   key: "languageSettingGlobal",
   default: "de",
});

export type { CombinedResponse, ConsiderationDetails, Coverage, Document, FormattedReply, langType, Question };

